import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { PrefilledMedicineAttributes, PrescriptionDetail } from "@interfaces";
import {
  BottomActionArea,
  BottomActionButton,
  ExtraWhiteSpace,
  SelectorOptions,
  SingleFormInput,
  TextAreaFormInput,
} from "@components";
import {
  calculateQuantityToDispense,
  handleNumericInputChange,
  parseDecimalInput,
} from "@utils";
import posologyFormats from "../../../data/posologyFormats.json";
import frequencyFormats from "../../../data/frequencyFormats.json";
import consumptionTypes from "../../../data/consumptionTypes.json";
import durationPeriods from "../../../data/durationPeriods.json";

const MedicineSelectionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});

const Label = styled(Typography)({
  fontWeight: 500,
  marginBottom: "4px",
  display: "block",
});

const FormRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: "8px",
  marginBottom: "4px",
  "& > :first-child": {
    flex: "40%",
  },
  "& > :nth-child(2)": {
    flex: "60%",
  },
});

type submitFunction = (formData: any) => void;

interface TreatmentFormValues extends PrescriptionDetail {
  compoundedIngredients: string;
}

interface FormManagement {
  onSubmit?: submitFunction;
  isLoading: boolean;
  showCompoundedIngredients?: boolean;
}

interface MedicineTreatmentFormProps {
  prefilledMedicineAttributes: PrefilledMedicineAttributes;
  formManagement: FormManagement;
}

export function MedicineTreatmentForm({
  prefilledMedicineAttributes,
  formManagement: { onSubmit = () => {}, isLoading, showCompoundedIngredients },
}: MedicineTreatmentFormProps) {
  const {
    activePrinciple,
    concentration,
    name,
    unit,
    prescriptionType,
    compoundedIngredients,
    posologyQuantity: prefilledPosologyQuantity,
    posologyFormat: prefilledPosologyFormat,
    frequencyQuantity: prefilledFrequencyQuantity,
    frequencyPeriod: prefilledFrequencyPeriod,
    durationQuantity: prefilledDurationQuantity,
    durationPeriod: prefilledDurationPeriod,
    consumptionType: prefilledConsumptionType,
    quantityToDispense: prefilledQuantityToDispense,
    indications,
  } = prefilledMedicineAttributes;
  const prefilledIsTabletOrCapsule =
    unit === "comprimidos" || unit === "cápsulas";

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<TreatmentFormValues>({
    defaultValues: {
      activePrinciple,
      name,
      concentration,
      compoundedIngredients,
      posologyQuantity: prefilledPosologyQuantity ?? 1,
      posologyFormat:
        prefilledPosologyFormat ??
        `${
          unit === "comprimidos"
            ? "comprimido"
            : unit === "cápsulas"
            ? "cápsula"
            : ""
        }`,
      frequencyQuantity: prefilledFrequencyQuantity,
      frequencyPeriod: prefilledFrequencyPeriod ?? "hora",
      durationQuantity: prefilledDurationQuantity,
      durationPeriod: prefilledDurationPeriod ?? "día",
      consumptionType:
        prefilledConsumptionType ?? prefilledIsTabletOrCapsule ? "oral" : "",
      quantityToDispense: prefilledQuantityToDispense,
      indications,
    },
  });

  const posologyQuantity = watch("posologyQuantity");
  const posologyFormat = watch("posologyFormat");
  const frequencyQuantity = watch("frequencyQuantity");
  const frequencyPeriod = watch("frequencyPeriod");
  const durationQuantity = watch("durationQuantity");
  const durationPeriod = watch("durationPeriod");

  const consumptionType = watch("consumptionType");
  const quantityToDispense = watch("quantityToDispense");

  const isTabletOrCapsule =
    posologyFormat === "comprimido" || posologyFormat === "cápsula";

  const quantityToDispenseDescription = useMemo(() => {
    if (prescriptionType === "HELD") {
      return isTabletOrCapsule
        ? "Total de comprimidos o cápsulas"
        : "Total de envases o cajas";
    } else {
      return "";
    }
  }, [prescriptionType, isTabletOrCapsule]);

  const handleNumericInputChangeWrapper = useCallback(
    (attributeName: keyof TreatmentFormValues, value: number | undefined) => {
      handleNumericInputChange(attributeName, value, clearErrors, setValue);
    },
    [clearErrors, setValue]
  );

  useEffect(() => {
    function fillQuantityToDispense() {
      if (prescriptionType === "HELD") {
        const unitsAreTabletOrCapsuleAndNoPosologyFormat =
          prefilledIsTabletOrCapsule && posologyFormat === undefined;
        if (unitsAreTabletOrCapsuleAndNoPosologyFormat || isTabletOrCapsule) {
          if (
            posologyQuantity &&
            frequencyQuantity &&
            durationQuantity &&
            frequencyPeriod &&
            durationPeriod
          ) {
            const calculatedQuantityToDispense = calculateQuantityToDispense(
              posologyQuantity,
              frequencyQuantity,
              durationQuantity,
              frequencyPeriod,
              durationPeriod
            );
            handleNumericInputChangeWrapper(
              "quantityToDispense",
              calculatedQuantityToDispense
            );
          }
        } else if (!isTabletOrCapsule) {
          handleNumericInputChangeWrapper("quantityToDispense", 1);
        }
      }
    }
    fillQuantityToDispense();
  }, [
    handleNumericInputChangeWrapper,
    prescriptionType,
    prefilledIsTabletOrCapsule,
    isTabletOrCapsule,
    posologyQuantity,
    posologyFormat,
    frequencyQuantity,
    frequencyPeriod,
    durationQuantity,
    durationPeriod,
  ]);

  useEffect(() => {
    if (isTabletOrCapsule) {
      clearErrors("consumptionType");
      setValue("consumptionType", "oral");
    }
  }, [isTabletOrCapsule, clearErrors, setValue]);

  return (
    <>
      <MedicineSelectionContainer>
        {showCompoundedIngredients && (
          <TextAreaFormInput
            title="Prescripción"
            parameterName="compoundedIngredients"
            errorText="Debes ingresar la descripción de la receta magistral"
            register={register}
            errors={errors}
            minHeight="150px"
          />
        )}
        <Box>
          <Label>Posología</Label>
          <FormRow>
            <SingleFormInput
              parameterName="posologyQuantity"
              errorText="Obligatorio"
              register={register}
              errors={errors}
              inputType="number"
              numericInputType="decimal"
              validate={(value: string) =>
                parseDecimalInput(value) > 0 ||
                "Debes ingresar una cantidad válida"
              }
              numericExternalValue={posologyQuantity}
              handleNumericExternalValueChange={(value) =>
                handleNumericInputChangeWrapper("posologyQuantity", value)
              }
            />
            <SelectorOptions
              control={control}
              name="posologyFormat"
              options={posologyFormats.singular}
              selectedValue={posologyFormat}
              relatedInputQuantity={posologyQuantity}
            />
          </FormRow>
        </Box>
        <Box>
          <Label>Cada</Label>
          <FormRow>
            <SingleFormInput
              parameterName="frequencyQuantity"
              errorText="Obligatorio"
              register={register}
              errors={errors}
              inputType="number"
              numericInputType="decimal"
              validate={(value: string) =>
                parseDecimalInput(value) > 0 ||
                "Debes ingresar una cantidad válida"
              }
              numericExternalValue={frequencyQuantity}
              handleNumericExternalValueChange={(value) =>
                handleNumericInputChangeWrapper("frequencyQuantity", value)
              }
            />
            <SelectorOptions
              control={control}
              name="frequencyPeriod"
              options={frequencyFormats.singular}
              selectedValue={frequencyPeriod}
              relatedInputQuantity={frequencyQuantity}
            />
          </FormRow>
        </Box>
        <Box>
          <Label>Duración</Label>
          <FormRow>
            <SingleFormInput
              parameterName="durationQuantity"
              errorText="Obligatorio"
              register={register}
              errors={errors}
              inputType="number"
              numericInputType="decimal"
              validate={(value: string) =>
                parseDecimalInput(value) > 0 ||
                "Debes ingresar una cantidad válida"
              }
              numericExternalValue={durationQuantity}
              handleNumericExternalValueChange={(value) =>
                handleNumericInputChangeWrapper("durationQuantity", value)
              }
            />

            <SelectorOptions
              control={control}
              name="durationPeriod"
              options={durationPeriods.singular}
              selectedValue={durationPeriod}
              relatedInputQuantity={durationQuantity}
              rules={{ required: "Obligatorio" }}
            />
          </FormRow>
        </Box>
        <SelectorOptions
          control={control}
          name="consumptionType"
          options={consumptionTypes}
          selectedValue={consumptionType}
          label="Vía"
          placeholder="Seleccionar"
          rules={{ required: "Obligatorio" }}
        />
        {quantityToDispenseDescription && (
          <FormRow>
            <SingleFormInput
              title="Cantidad a dispensar"
              parameterName="quantityToDispense"
              errorText="Obligatorio"
              description={quantityToDispenseDescription}
              register={register}
              errors={errors}
              units="unidades"
              inputType="number"
              numericInputType="integer"
              validate={(value: string) =>
                parseDecimalInput(value) > 0 ||
                "Debes ingresar una cantidad válida"
              }
              numericExternalValue={quantityToDispense}
              handleNumericExternalValueChange={(value) => {
                handleNumericInputChangeWrapper("quantityToDispense", value);
              }}
            />
          </FormRow>
        )}
        <TextAreaFormInput
          title="Indicaciones"
          parameterName="indications"
          register={register}
          errors={errors}
          minHeight="80px"
        />
      </MedicineSelectionContainer>
      <ExtraWhiteSpace />

      <BottomActionArea>
        <BottomActionButton
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Siguiente"
          )}
        </BottomActionButton>
      </BottomActionArea>
    </>
  );
}
