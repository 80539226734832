import { useCallback, useEffect, useState } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { Box, FormControl, Skeleton, styled, TextField } from "@mui/material";
import {
  NumericInputType,
  parseNumericInputValueToString,
  parseStringToNumericInputValue,
} from "@utils";
import { theme as oldTheme } from "@config";

const StyledTextField = styled(TextField)({
  backgroundColor: oldTheme.pallete.background.white,
  "& input": {
    minHeight: "32px",
    textAlign: "left",
    padding: "12px",
  },
});

const InputLabel = styled("label")({
  fontWeight: 500,
  paddingBottom: "4px",
  color: oldTheme.pallete.text.black,
});

const ItalicLabel = styled("span")({
  fontStyle: "italic",
  fontWeight: 400,
});

const InputExample = styled("span")({
  display: "flex",
  justifyContent: "flex-end",
  fontSize: "12px",
  color: oldTheme.pallete.text.gray,
  paddingTop: "4px",
});

const RelativeContainer = styled(Box)({
  position: "relative",
});

export const ErrorMessage = styled("span")({
  position: "absolute",
  fontSize: "12px",
  color: oldTheme.pallete.errors.primary,
});

const UnitsSpan = styled("span")({
  position: "absolute",
  right: "2.5%",
  bottom: "14px",
  color: oldTheme.pallete.text.gray,
});

interface InputProp {
  title?: string;
  parameterName: string;
  errorText?: string;
  showOptionalLabel?: boolean;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  description?: string;
  placeholder?: string;
  inputType?: "text" | "number" | "email" | "password";
  numericInputType?: NumericInputType;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  validate?: (value: any) => boolean | string;
  units?: string;
  autoComplete?: string;
}

interface NumericInputProps {
  numericExternalValue?: number | string;
  handleNumericExternalValueChange?: (value: number | undefined) => void;
  numericMaxValue?: number;
  numericMinValue?: number;
}

export const SingleFormInput: React.FC<InputProp & NumericInputProps> = ({
  title,
  parameterName,
  errorText,
  showOptionalLabel,
  register,
  errors,
  description,
  placeholder,
  inputType = "text",
  numericInputType,
  onChange,
  onKeyDown,
  onBlur,
  validate,
  units,
  autoComplete,
  numericExternalValue,
  handleNumericExternalValueChange,
  numericMaxValue = 999999999,
  numericMinValue = 0,
}) => {
  const isNumberType = inputType === "number";
  const [numericInternalValue, setNumericInternalValue] = useState<string>(
    numericExternalValue?.toString() ?? ""
  );

  const handleNumericInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!numericInputType || !handleNumericExternalValueChange) return;
      const stringValue = parseNumericInputValueToString(
        event.target.value,
        numericInputType
      );
      if (stringValue === "") return setNumericInternalValue("");
      const numericValue = parseStringToNumericInputValue(
        stringValue,
        numericInputType
      );
      if (
        numericInputType === "document_number" ||
        (numericMinValue <= numericValue && numericValue <= numericMaxValue)
      ) {
        handleNumericExternalValueChange(numericValue);
        return setNumericInternalValue(stringValue);
      }
    },
    [
      numericInputType,
      numericMaxValue,
      numericMinValue,
      handleNumericExternalValueChange,
    ]
  );

  const onNumericInputBlur = useCallback(() => {
    if (
      !numericInputType ||
      !handleNumericExternalValueChange ||
      !numericInternalValue
    )
      return;
    const numericValue = parseStringToNumericInputValue(
      numericInternalValue,
      numericInputType
    );
    handleNumericExternalValueChange(numericValue);
  }, [
    numericInputType,
    handleNumericExternalValueChange,
    numericInternalValue,
  ]);

  // Update internal value when external value changes
  useEffect(() => {
    setNumericInternalValue(numericExternalValue?.toString() ?? "");
  }, [numericExternalValue]);

  return (
    <FormControl fullWidth>
      {title && (
        <InputLabel>
          {title} {showOptionalLabel && <ItalicLabel>(Opcional)</ItalicLabel>}
        </InputLabel>
      )}
      <StyledTextField
        type={isNumberType ? "text" : inputType}
        placeholder={placeholder}
        variant="outlined"
        error={!!errors[parameterName]}
        {...register(parameterName, {
          required: errorText ?? false,
          onChange: onChange,
          onBlur: onBlur,
          validate: validate,
        })}
        onKeyDown={onKeyDown}
        inputProps={{
          autoComplete: autoComplete ?? "on",
          inputMode: isNumberType
            ? numericInputType === "decimal"
              ? "decimal"
              : "numeric"
            : "text",
        }}
        InputProps={{
          disableUnderline: true,
        }}
        {...(isNumberType && {
          value: numericInternalValue,
          onChange: handleNumericInputChange,
          onBlur: onNumericInputBlur,
        })}
      />

      <RelativeContainer>
        <UnitsSpan>{units}</UnitsSpan>

        {!!errors[parameterName] && (
          <ErrorMessage>
            {errors[parameterName]?.message?.toString() ??
              "Este item es obligatorio"}
          </ErrorMessage>
        )}
      </RelativeContainer>

      {description && <InputExample>{description}</InputExample>}
    </FormControl>
  );
};

const SkeletonContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  width: "100%",
});

export const SingleFormInputSkeleton: React.FC = () => {
  return (
    <SkeletonContainer>
      <Skeleton variant="rectangular" height={23} width="30%" />
      <Skeleton variant="rectangular" height={56} width="100%" />
    </SkeletonContainer>
  );
};
