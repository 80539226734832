export enum ItemType {
  PRODUCT = "PRODUCT",
  GENERIC = "GENERIC",
  CONSOLIDATED_PRODUCT = "CONSOLIDATED_PRODUCT",
}

export type PrescriptionType =
  | "CHECK"
  | "HELD"
  | "SIMPLE"
  | "NOT_REQUIRED"
  | null;

export type AvailabilityStatus =
  | "AVAILABLE_24_HRS"
  | "AVAILABLE"
  | "REPRESENTATION"
  | "SOLD_OUT"
  | "HIDDEN";

export interface ExternalPrice {
  seller: string;
  amount: number;
  saleAmount: number;
  lastUpdate: string;
  product?: SimplifiedSearchProduct;
  url?: string;
}

export interface SearchProduct {
  type: ItemType.PRODUCT | ItemType.CONSOLIDATED_PRODUCT;
  id: number;
  name: string;
  activePrinciple: string;
  concentration: string;
  laboratory: string;
  quantity: number;
  unit: string;
  format: string;
  completeFormat: string;
  prescriptionType: PrescriptionType;
  price: number;
  discount?: {
    price: number;
  };
  isMedicine: boolean;
  subCategories: {
    name: string;
    slug: string;
  }[];
  slug: string;
  imagesUrl: string[];
  externalPrices: ExternalPrice[];
  availability: {
    status: AvailabilityStatus;
    stock: number;
  };
  alternatives: any[];
}

export interface SearchGeneric {
  type: ItemType.GENERIC;
  productsConsolidated: number;
  activePrinciple: string;
  concentration: string;
  laboratories: string[];
  format: string;
  completeFormat: string;
  unit: string;
  prescriptionType: PrescriptionType;
  price: number;
  externalPrices: ExternalPrice[];
}

export type Medicine = SearchProduct | SearchGeneric;

export interface PrefilledMedicineAttributes {
  activePrinciple?: string;
  concentration?: string;
  name?: string;
  unit?: string;
  prescriptionType?: PrescriptionType;
  compoundedIngredients?: string;
  posologyQuantity?: number;
  posologyFormat?: string;
  frequencyQuantity?: number;
  frequencyPeriod?: string;
  durationQuantity?: number;
  durationPeriod?: string;
  consumptionType?: string;
  quantityToDispense?: number;
  indications?: string;
}

interface SimplifiedSearchProduct
  extends Omit<
    SearchProduct,
    | "type"
    | "id"
    | "price"
    | "discount"
    | "isMedicine"
    | "subCategories"
    | "slug"
    | "imagesUrl"
    | "externalPrices"
    | "availability"
    | "alternatives"
  > {}

export interface Suggestion {
  text: string;
}

export interface MedicineSuggestion extends Suggestion {
  itemType: ItemType;
  activePrincipleCount: number;
  associatedProduct?: string[];
}

export interface Procedure {
  code: string;
  description: string;
  group: string;
  subgroup: string;
}

export interface ProcedureSuggestion extends Suggestion {
  code: string;
  description: string;
  group: string;
  subgroup: string;
}

export interface MedicineSearch {
  preliminarySearchResults: Suggestion[];
  finalSearchResults: {
    searchType: ItemType;
    processedMedicines: (SearchGeneric | SearchProduct)[];
  };
  searchedQuery: string;
}
