import { useMemo } from "react";
import {
  Box,
  Divider,
  IconButton,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { HistoricRequestCardDetails } from "../../../interfaces";
import { theme } from "../../../config";
import { getUnitForQuantity } from "../../../utils";

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  border: "1px solid #eaeaea",
  borderRadius: "8px",
  backgroundColor: theme.pallete.background.white,
  gap: "4px",
  padding: "8px 16px",
});

const Row = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const InfoColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "calc(100% - 40px)",
  gap: "4px",
});

const ActionColumn = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-start",
  flex: 0,
});

const ProductName = styled(Typography)({
  fontWeight: 500,
  color: theme.pallete.text.black,
  textWrap: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const ProductDetails = styled(Typography)({
  fontSize: "14px",
  color: theme.pallete.text.gray,
});

const CompoundedIngredients = styled(ProductDetails)({
  color: theme.pallete.text.black,
  whiteSpace: "pre-line",
});

const TreatmentDetail = styled(Typography)({
  fontSize: "14px",
  color: theme.pallete.text.gray,
});

const ActionButton = styled(IconButton)({
  color: theme.pallete.text.primary,
  padding: 0,
});

interface PrescriptionDetailCardProps {
  detail: HistoricRequestCardDetails;
  handleDelete?: (detail: HistoricRequestCardDetails) => void;
}

export function PrescriptionDetailCard(props: PrescriptionDetailCardProps) {
  const { detail, handleDelete } = props;
  const {
    activePrinciple,
    concentration,
    name,
    laboratory,
    compoundedIngredients,
    posologyQuantity,
    posologyFormat,
    frequencyQuantity,
    frequencyPeriod,
    durationQuantity,
    durationPeriod,
    quantityToDispense,
    indications,
  } = detail;

  const cardTitle = useMemo(() => {
    const hasLessThan4ActivePrinciples =
      activePrinciple && activePrinciple.split(";").length < 4;
    const hasLessThan4Concentrations =
      concentration && concentration.split(";").length < 4;
    if (hasLessThan4ActivePrinciples || hasLessThan4Concentrations) {
      return `${activePrinciple ?? ""} ${concentration ?? ""}`.trim();
    } else {
      return name;
    }
  }, [name, activePrinciple, concentration]);

  const cardSubTitle = useMemo(() => {
    const parsedName =
      name && activePrinciple && name !== activePrinciple && name !== cardTitle
        ? name
        : null;
    const parsedLaboratory =
      laboratory && laboratory !== "TO_DO" ? laboratory : null;
    return [parsedName, parsedLaboratory].filter(Boolean).join(" · ");
  }, [name, activePrinciple, laboratory, cardTitle]);

  const consumptionDetail = useMemo(() => {
    if (posologyQuantity && posologyFormat) {
      return `${posologyQuantity} ${getUnitForQuantity(
        posologyFormat,
        posologyQuantity
      )} cada ${frequencyQuantity} ${getUnitForQuantity(
        frequencyPeriod,
        frequencyQuantity
      )}`;
    }
    return "";
  }, [posologyQuantity, posologyFormat, frequencyQuantity, frequencyPeriod]);

  const treatmentDuration = useMemo(() => {
    if (durationQuantity && durationPeriod) {
      return `Tratamiento por ${durationQuantity} ${getUnitForQuantity(
        durationPeriod,
        durationQuantity
      )}`;
    }
    return "";
  }, [durationQuantity, durationPeriod]);

  const quantityToDispenseText = useMemo(() => {
    if (quantityToDispense) {
      return `Cant. a dispensar: ${quantityToDispense} ${getUnitForQuantity(
        "unidad",
        quantityToDispense
      )}`;
    }
    return "";
  }, [quantityToDispense]);

  return (
    <Container>
      <Row>
        <InfoColumn>
          {cardTitle && <ProductName>{cardTitle}</ProductName>}
          {cardSubTitle && <ProductDetails>{cardSubTitle}</ProductDetails>}
          {compoundedIngredients && (
            <CompoundedIngredients>
              {compoundedIngredients}
            </CompoundedIngredients>
          )}
          {consumptionDetail && (
            <TreatmentDetail>{consumptionDetail}</TreatmentDetail>
          )}
          {treatmentDuration && (
            <TreatmentDetail>{treatmentDuration}</TreatmentDetail>
          )}
          {quantityToDispenseText && (
            <TreatmentDetail>{quantityToDispenseText}</TreatmentDetail>
          )}
        </InfoColumn>
        {handleDelete && (
          <ActionColumn>
            <ActionButton onClick={() => handleDelete(detail)}>
              <DeleteOutlinedIcon />
            </ActionButton>
          </ActionColumn>
        )}
      </Row>
      {indications && (
        <>
          <Divider />
          <TreatmentDetail>{indications}</TreatmentDetail>
        </>
      )}
    </Container>
  );
}

PrescriptionDetailCard.Skeleton = function PrescriptionDetailCardSkeleton() {
  return (
    <Container>
      <Row>
        <InfoColumn>
          <ProductName>
            <Skeleton variant="text" width="90%" />
          </ProductName>
          <ProductDetails>
            <Skeleton variant="text" width="90%" />
          </ProductDetails>
          <TreatmentDetail>
            <Skeleton variant="text" width="90%" />
          </TreatmentDetail>
        </InfoColumn>
        <ActionColumn></ActionColumn>
      </Row>
    </Container>
  );
};
